<template>
  <v-form id="recurringForm" v-model="valid">
    <v-card class="d-flex flex-column">
      <v-card-title class="primary--text text-h6">
        <v-icon color="primary" class="mr-2">mdi-calendar-today</v-icon>
        {{ $t("donationForm.recurringForm.title") }}
      </v-card-title>
      <v-card-text>
        <v-btn-toggle
          v-model="selectedAmount"
          :rules="[$v.required()]"
          class="flex-wrap fix-borders"
          active-class="secondary white--text"
        >
          <v-btn v-for="index in value.recurringDonationGrid" :key="index" min-width="100px">
            <div v-if="index == 'custom'">
              <v-text-field
                :rules="
                  value.recurringDonationGrid[selectedAmount] == 'custom'
                    ? [$v.required(), $v.min(1), $v.max(99999)]
                    : []
                "
                solo
                flat
                :label="$t('donationForm.other')"
                class="input-custom-amount"
                v-model="customAmount"
                suffix="€"
                type="number"
                min="1"
                hide-details
              ></v-text-field>
            </div>
            <div v-else class="text-h6 font-weight-medium px-1">
              {{ index | amountNoDecimal("EUR") }}
            </div>
          </v-btn>
        </v-btn-toggle>
      </v-card-text>
      <v-card-text class="pt-0">
        <TaxDeduction
          v-model="cost_after_deduction"
          v-if="$store.state.donationForm.charity.communicatesOnFiscalReduction"
        />
      </v-card-text>
      <hr class="primary" v-show="selectedAmount != null" />
      <v-expand-transition>
        <v-card-actions v-show="selectedAmount != null" class="flex-column flex-sm-row">
          <div class="pa-2 pt-0">
            {{ $t("donationForm.recurringForm.text_1") }}
            <v-select
              :items="recurringDays"
              class="d-inline-block day-input mx-1"
              v-model="dayofmonth"
              dense
              :rules="[$v.required()]"
              hide-details
              style="max-width: 70px"
            >
              <template v-slot:selection="{ item }">
                <div class="font-weight-medium text-center full-width">{{ item }}</div>
              </template>
            </v-select>
            {{ $t("donationForm.recurringForm.text_2") }}
            <div v-if="dayofmonth == ''" class="caption red--text">{{ $t("errors.validator.required") }}</div>
            <div v-else-if="dayofmonth > 28" class="caption red--text">
              {{ $t("errors.validator.max", { 0: 28 }) }}
            </div>
            <div v-else-if="dayofmonth < 1" class="caption red--text">{{ $t("errors.validator.min", { 0: 1 }) }}</div>
            <div class="caption mt-2" v-html="$t('donationForm.recurringForm.text_3', { date: next_date })"></div>
          </div>
          <v-spacer />
          <v-btn color="secondary" v-on:click="nextStep()" :disabled="!valid" class="ma-2">{{
            $t("prop.continue")
          }}</v-btn>
        </v-card-actions>
      </v-expand-transition>
    </v-card>
  </v-form>
</template>

<script>
const moment = require("moment");
import TaxDeduction from "./taxDeduction.vue";
export default {
  components: { TaxDeduction },
  name: "RecurringForm",
  props: {
    value: Object,
  },
  mounted() {},
  methods: {
    reset() {
      this.$data.selectedAmount = null;
    },
    nextStep() {
      let amount = 0;
      if (this.value.recurringDonationGrid[this.selectedAmount] == "custom") {
        amount = this.customAmount;
      } else {
        amount = this.value.recurringDonationGrid[this.selectedAmount];
      }

      sessionStorage.setItem("custom_tip_amount", 0);
      sessionStorage.setItem("donation_amount", amount);
      sessionStorage.setItem("donation_recurring", true);
      sessionStorage.setItem("donation_dayofmonth", this.dayofmonth);

      this.bustIframeRedirect();
    },
    bustIframeRedirect() {
      let url = `/d/${this.$store.state.donationForm.id}-${this.$store.state.donationForm.slug}/details`;
      if (window.self !== window.top) {
        top.window.location.href = url;
      } else {
        this.$router.push(url);
      }
    },
  },
  watch: {
    selectedAmount(value) {
      if (value !== null) {
        this.$emit("selected");
      }
    },
  },
  data() {
    return {
      selectedAmount: null,
      customAmount: null,
      dayofmonth: moment().date() > 28 ? 28 : moment().date(),
      valid: false,
    };
  },
  computed: {
    recurringDays: () => [...Array(28)].map((_, i) => i + 1),
    donation_amount: function () {
      let amount = 0;
      if (this.value.recurringDonationGrid[this.selectedAmount] == "custom") {
        amount = this.customAmount;
      } else {
        amount = this.value.recurringDonationGrid[this.selectedAmount];
      }
      return amount;
    },
    cost_after_deduction: function () {
      let result = 0;
      if (this.$store.state.donationForm.charity.fiscalReceiptInformation.eligibleColuche) {
        const colucheMaxAmount = this.$store.state.settings.fiscalReduction.colucheDonationLimit;
        if (this.donation_amount && colucheMaxAmount < parseFloat(this.donation_amount)) {
          let colucheAmount = colucheMaxAmount * this.$store.state.settings.fiscalReduction.coluche;
          let stdAmount =
            (this.donation_amount - colucheMaxAmount) * this.$store.state.settings.fiscalReduction.standard;
          result = colucheAmount + stdAmount;
        } else {
          result = this.donation_amount * this.$store.state.settings.fiscalReduction.coluche;
        }
      } else {
        result = this.donation_amount * this.$store.state.settings.fiscalReduction.standard;
      }
      return result;
    },
    next_date: function () {
      let mom = new moment();
      mom.add(1, "month");
      mom.set("date", this.dayofmonth);
      return mom.format("DD/MM/YYYY");
    },
  },
};
</script>

<style scoped>
.input-custom-amount {
  width: 110px;
  position: relative;
  top: 0.5em;
}
.input-custom-amount > div {
  background: transparent;
}
.input-custom-amount >>> div {
  min-height: auto;
  background: transparent;
}
.input-custom-amount >>> label {
  font-size: 0.8em;
  font-weight: bold;
}
.input-custom-amount >>> input {
  -moz-appearance: textfield;
}
.input-custom-amount >>> input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
.full-width {
  width: 100%;
}
hr {
  border-top: 2px solid #21a6df;
}
.v-application--is-ltr .fix-borders > .v-btn.v-btn:not(:first-child) {
  border-left-width: 1px !important;
}
</style>
