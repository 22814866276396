<template>
  <div>
    <Header class="mb-3" />
    <v-container class="pa-0 pa-sm-3">
      <v-row justify="center">
        <v-col xl="8">
          <router-view></router-view>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Header from "./components/header.vue";
export default {
  components: { Header },
  data() {
    return {};
  },
  mounted() {
    if (this.$store.state.donationForm.colorIdentity) {
      this.$vuetify.theme.themes["light"].primary = this.$store.state.donationForm.colorIdentity.mainColor;
      this.$vuetify.theme.themes["light"].secondary = this.$store.state.donationForm.colorIdentity.buttonColor;
    }
  },
  computed: {},
};
</script>

<style></style>
