import Vue from "vue";
import Router from "vue-router";
import NotFound from "./pages/_notFound";
import store from "./store";
import axios from "./plugins/axios";

// const PassThrough = {
//   render(c) {
//     return c("router-view");
//   },
// };

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    /*{
      path: "/",
      redirect: () => {
        window.location.replace(store.state.settings.fundraisingHomeUrl || "https://www.alvarum.com");
        return false;
      },
    },*/
    {
      path: "/d/:donationFormId-:slug",
      component: require("./pages/donationForm/_layout").default,
      children: [
        {
          path: "/",
          component: require("./pages/donationForm/landing").default,
        },
        {
          path: "details",
          component: require("./pages/donationForm/donorDetails").default,
          meta: {
            public: true,
          },
        },
        {
          path: "payment",
          component: require("./pages/donationForm/payment").default,
          meta: {
            public: true,
          },
        },
        {
          path: ":donationId/summary/:statusId?",
          component: require("./pages/donationForm/summary").default,
          meta: {
            public: true,
          },
        },
      ],
    },
    {
      path: "/e/:donationFormId-:slug",
      component: require("./pages/donationForm/embed").default,
    },
    {
      path: "/:catchAll(.*)*",
      component: NotFound,
    },
  ],
});

router.beforeEach(async (to, from, next) => {
  // Load donation form into state if needed
  if (to.params.donationFormId) {
    if (!store.state.donationForm || store.state.donationForm.id != to.params.donationFormId) {
      let url = `/donationForms/forms/${to.params.donationFormId}`;
      let result = await axios.get(url);

      result.donationGrid = JSON.parse(result.donationGrid);
      result.donationGrid.push("custom");
      result.recurringDonationGrid = JSON.parse(result.recurringDonationGrid);
      result.recurringDonationGrid.push("custom");

      if (result.banner && result.banner.resource) {
        result.bannerUrl = store.state.settings.rscResourceBaseUrl + "/" + result.banner.resource.fileName;
      }
      if (result.charity.charityProfile.logo) {
        result.logoUrl =
          store.state.settings.resourcesBaseUrl +
          "/images/charityProfiles/charityProfile" +
          result.charity.charityProfile.id +
          "/" +
          result.charity.charityProfile.logo;
      }

      store.state.donationForm = result;
    }
    // Check slug
    if (to.path.startsWith("/d/") && to.params.slug != store.state.donationForm.slug) {
      return next(`/d/${store.state.donationForm.id}-${store.state.donationForm.slug}`);
    }
  }

  return next();
});

export default router;
