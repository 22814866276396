<template>
  <v-container :class="{ 'pa-1': $vuetify.breakpoint.xs }">
    <component :is="`style`" v-html="$store.state.donationForm.colorIdentity.customCss"></component>
    <v-row dense justify="center">
      <v-col cols="12" lg="6" xl="5">
        <SingleForm
          ref="singleForm"
          v-model="donationForm"
          @selected="$refs.recurringForm && $refs.recurringForm.reset()"
        />
      </v-col>
      <v-col cols="12" lg="6" xl="5" v-if="donationForm.recurringFormEnabled">
        <RecurringForm ref="recurringForm" v-model="donationForm" @selected="$refs.singleForm.reset()" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import SingleForm from "./components/singleForm";
import RecurringForm from "./components/recurringForm";
export default {
  components: {
    SingleForm,
    RecurringForm,
  },
  data() {
    return {
      bannerUrl: null,
      donationForm: {
        recurringDonationGrid: [],
        donationGrid: [],
        charity: { charityProfile: {} },
      },
    };
  },
  async mounted() {
    document.documentElement.classList.add("embed");
    this.donationForm = this.$store.state.donationForm;
    if (this.$store.state.donationForm.colorIdentity) {
      this.$vuetify.theme.themes["light"].primary = this.$store.state.donationForm.colorIdentity.mainColor;
      this.$vuetify.theme.themes["light"].secondary = this.$store.state.donationForm.colorIdentity.buttonColor;
    }
  },
};
</script>

<style>
.embed,
.embed body,
.embed .theme--light.v-application,
.embed .theme--dark.v-application {
  background: none transparent !important;
}
html,
body {
  overflow-y: auto;
}
</style>
