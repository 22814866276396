<template>
  <v-container :class="$vuetify.breakpoint.smAndUp ? 'pt-0' : 'pa-0'">
    <component :is="`style`" v-html="$store.state.donationForm.colorIdentity.customCss"></component>
    <img
      v-if="$store.state.donationForm.colorIdentity.logoUrl"
      id="logo_image"
      :src="$store.state.settings.rscResourceBaseUrl + '/' + $store.state.donationForm.colorIdentity.logoUrl"
    />
    <component :is="`style`" v-html="background_css"></component>
    <v-row v-if="donationForm.bannerUrl">
      <v-col class="py-0">
        <v-img :src="`${donationForm.bannerUrl}`" class="mb-3" />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <h2 class="primary--text text-uppercase">{{ donationForm.title }}</h2>
      </v-col>
    </v-row>
    <v-row v-if="isClosed">
      <v-col>
        <v-card :class="!$vuetify.breakpoint.smAndDown ? 'mr-5 flex-grow-1' : 'flex-grow-1'">
          <v-card-text>
            <v-list>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon large color="deep-orange"> mdi-close-circle </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    <span class="text-h6 primary--text">{{ this.$t("donationForm.closedMessage") }}</span>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col cols="12" md="8">
        <v-row>
          <v-col>
            <SingleForm
              ref="singleForm"
              v-model="donationForm"
              @selected="$refs.recurringForm && $refs.recurringForm.reset()"
            />
          </v-col>
        </v-row>
        <v-row v-if="donationForm.recurringFormEnabled">
          <v-col>
            <RecurringForm ref="recurringForm" v-model="donationForm" @selected="$refs.singleForm.reset()" />
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="4">
        <v-row v-if="!!donationForm.message">
          <v-col>
            <v-card>
              <v-card-title class="primary--text py-2">
                <v-icon color="primary" class="mr-2">mdi-help-circle-outline</v-icon>
                {{ this.$t("donationForm.whyDonating") }}
              </v-card-title>
              <v-divider />
              <v-card-text class="text-caption text--darken-4 grey--text text-justify read-more">
                <div v-html="donationForm.message"></div>
                <a
                  onclick="this.parentElement.classList.add('expanded');return false;"
                  class="read-more-btn text-caption"
                  href="#"
                >
                  {{ $t("prop.read_more") }}
                </a>
                <a
                  onclick="this.parentElement.classList.remove('expanded');return false;"
                  class="read-less-btn text-caption"
                  href="#"
                >
                  {{ $t("prop.read_less") }}
                </a>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <Security />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <CharityInfo />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import SingleForm from "./components/singleForm";
import RecurringForm from "./components/recurringForm";
import Security from "./components/security.vue";
import CharityInfo from "./components/charityInfo.vue";
export default {
  components: {
    SingleForm,
    RecurringForm,
    Security,
    CharityInfo,
  },
  data() {
    return {
      bannerUrl: null,
      donationForm: {
        recurringDonationGrid: [],
        donationGrid: [],
        charity: { charityProfile: {} },
        colorIdentity: {},
      },
      background_css: "",
    };
  },
  async mounted() {
    this.donationForm = this.$store.state.donationForm;
    if (this.donationForm.colorIdentity.backgroundUrl) {
      this.background_css = `
      .theme--light.v-application {
        background-image: url(${this.$store.state.settings.rscResourceBaseUrl}/${this.donationForm.colorIdentity.backgroundUrl});
        background-repeat: no-repeat;
        background-position: top center;
        background-size: 100% auto;
      }`;
    }
  },
  updated() {
    var el = document.querySelector(".read-more");
    if (el && el.clientHeight > 150) {
      el.classList.add("large");
    }
  },
  computed: {
    isClosed() {
      return this.donationForm.closingDate && new Date(this.donationForm.closingDate) <= new Date();
    },
  },
};
</script>

<style lang="scss">
.read-more .read-more-btn {
  display: none;
}
.read-more .read-less-btn {
  display: none;
}
.read-more.large .read-more-btn {
  display: block;
}
.read-more.large div {
  overflow: hidden;
  max-height: 5em;
  text-overflow: ellipsis;
}
.read-more.large.expanded div {
  max-height: 100%;
}
.read-more.large.expanded .read-more-btn {
  display: none;
}
.read-more.large.expanded .read-less-btn {
  display: block;
}
#logo_image {
  display: none;
}
.theme--light.v-application {
  background: #f3f3f3;
}
.form_grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 1em;
  row-gap: 1em;
}

@import "~vuetify/src/styles/settings/_variables";

@media #{map-get($display-breakpoints, 'sm-and-down')} {
  .form_grid {
    grid-template-columns: 1fr;
  }
}

.form_grid > form {
  height: 100%;
}

.form_grid > form > div {
  height: 100%;
}

.v-application .text-caption p {
  margin-bottom: 0px;
}
</style>
