<template>
  <v-container :class="$vuetify.breakpoint.smAndUp ? 'pt-0' : 'pa-0'">
    <v-row>
      <v-col cols="12" md="8">
        <v-card>
          <v-card-title class="py-2 primary--text">
            <v-icon color="primary" class="mr-2">mdi-account-details</v-icon>
            {{ $t("donationForm.donorDetails.title") }}
          </v-card-title>
          <v-card-text>
            <v-form @submit.prevent="nextStep" id="detailsForm" v-model="detailsFormValid">
              <v-row dense>
                <v-col>
                  <v-text-field
                    :label="$t('donationForm.donorDetails.email')"
                    :rules="[$v.required(), $v.isEmail()]"
                    autocomplete="email"
                    name="email"
                    required
                    v-model="donorDetails.email"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col>
                  <v-radio-group v-model="donorType" row>
                    <v-radio
                      :label="$t('donationForm.donorDetails.individual')"
                      value="individual"
                      class="pr-6"
                    ></v-radio>
                    <v-radio :label="$t('donationForm.donorDetails.organization')" value="organization"></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="4">
                  <v-select
                    v-model="donorDetails.civility"
                    :items="toolkit.civility"
                    item-text="label"
                    item-value="id"
                    :label="$t('donationForm.donorDetails.gender')"
                    :rules="[$v.required()]"
                  ></v-select>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    :label="$t('donationForm.donorDetails.firstName')"
                    :rules="[$v.required()]"
                    name="fname"
                    autocomplete="given-name"
                    required
                    v-model="donorDetails.firstName"
                  ></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    :label="$t('donationForm.donorDetails.lastName')"
                    :rules="[$v.required()]"
                    name="lname"
                    autocomplete="family-name"
                    required
                    v-model="donorDetails.lastName"
                  ></v-text-field>
                </v-col>
              </v-row>
              <div v-if="donorType == 'organization'">
                <v-row dense>
                  <v-col cols="4" md="3">
                    <v-text-field
                      :label="$t('donationForm.donorDetails.organizationStatus')"
                      :rules="[$v.required()]"
                      name="organizationStatus"
                      autocomplete="organizationStatus"
                      required
                      v-model="donorDetails.organizationStatus"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="8" md="6">
                    <v-text-field
                      :label="$t('donationForm.donorDetails.organizationName')"
                      :rules="[$v.required()]"
                      name="organizationName"
                      autocomplete="organizationName"
                      required
                      v-model="donorDetails.organizationName"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-text-field
                      :label="$t('donationForm.donorDetails.organizationIdentificationNumber')"
                      :rules="[donorDetails.idCountry === 74 ? $v.required() : '']"
                      autocomplete="organizationIdentificationNumber"
                      name="organizationIdentificationNumber"
                      required
                      v-model="donorDetails.organizationIdentificationNumber"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </div>
              <v-row dense>
                <v-col>
                  <v-text-field
                    :label="$t('donationForm.donorDetails.address')"
                    :rules="[$v.required()]"
                    autocomplete="street-address"
                    name="address"
                    required
                    multi-line
                    v-model="donorDetails.address"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="4">
                  <v-text-field
                    :label="$t('donationForm.donorDetails.zipCode')"
                    :rules="[$v.required()]"
                    autocomplete="postal-code"
                    name="zip"
                    required
                    v-model="donorDetails.zipCode"
                  ></v-text-field>
                </v-col>
                <v-col cols="8">
                  <v-text-field
                    :label="$t('donationForm.donorDetails.city')"
                    :rules="[$v.required()]"
                    autocomplete="address-level2"
                    name="city"
                    required
                    v-model="donorDetails.city"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col>
                  <v-select
                    v-model="donorDetails.idCountry"
                    :label="$t('donationForm.donorDetails.country')"
                    :items="toolkit.countries"
                    item-text="label"
                    item-value="id"
                    :rules="[$v.required()]"
                  ></v-select>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions class="pa-4">
            <p class="mx-1 caption">
              {{ $t("donationForm.donorDetails.terms") }}
              <a target="_blank" :href="$t('footer.terms_and_conditions.link')">
                {{ $t("footer.terms_and_conditions.text") }}
              </a>
            </p>
            <v-spacer />
            <v-btn
              raised
              type="submit"
              color="secondary"
              form="detailsForm"
              :disabled="!detailsFormValid || summaryEditing"
            >
              {{ $t("prop.continue") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="12" md="4">
        <v-row>
          <v-col>
            <Summary v-on:start-edit="summaryEditing = true" v-on:finish-edit="summaryEditing = false" />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <Security />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Security from "./components/security.vue";
import Summary from "./components/summary.vue";
export default {
  components: {
    Summary,
    Security,
  },
  mounted() {
    this.donorDetails = JSON.parse(sessionStorage.getItem("donor_details")) || {};
    this.donorDetails.idCountry = this.$store.state.donationForm.charity.charityProfile.idCountry;
    this.getToolkit();
  },
  data() {
    return {
      donorDetails: {},
      donorType: "individual",
      toolkit: {},
      detailsFormValid: true,
      summaryEditing: false,
    };
  },
  watch: {
    donorDetails: {
      handler(val) {
        sessionStorage.setItem("donor_details", JSON.stringify(val));
      },
      deep: true,
    },
  },
  methods: {
    async getToolkit() {
      this.toolkit = await this.$axios.get("/common/toolkit");
      this.toolkit.countries.map((r) => (r.label = this.$t(r.labelKey)));
      this.toolkit.civility.map((r) => (r.label = this.$t(r.label)));
    },
    nextStep() {
      this.$router.push(`/d/${this.$route.params.donationFormId}-${this.$route.params.slug}/payment`);
    },
  },
};
</script>

<style></style>
