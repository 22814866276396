<template>
  <v-container :class="$vuetify.breakpoint.smAndUp ? 'pt-0' : 'pa-0'">
    <v-overlay :value="isLoading" absolute opacity="0.2">
      <v-row>
        <v-col class="text-center">
          <v-progress-circular indeterminate :size="50" color="primary"></v-progress-circular>
        </v-col>
      </v-row>
    </v-overlay>
    <v-row>
      <v-col cols="12" md="8">
        <v-card>
          <v-card-title class="py-2 primary--text">
            <v-icon color="primary" class="mr-2">mdi-credit-card</v-icon>
            {{ $t("donationForm.payment.title") }}
          </v-card-title>
          <v-card-text class="pt-2">
            <form id="hipay-form">
              <div id="hipay-hostedfields-form"></div>
              <div id="hipay-error-message"></div>
            </form>
          </v-card-text>
          <v-card-actions class="pa-4 pt-2">
            <v-spacer />
            <v-btn raised :disabled="!isValid || isLoading || summaryEditing" color="secondary" @click="pay()">{{
              $t("prop.continue")
            }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="12" md="4">
        <v-row>
          <v-col>
            <Summary v-on:start-edit="summaryEditing = true" v-on:finish-edit="summaryUpdated" />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <DonorSummary />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <Security />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DonorSummary from "./components/donorSummary.vue";
import Security from "./components/security.vue";
import Summary from "./components/summary.vue";
import paymentStatus from "../../util/paymentStatus";

export default {
  components: { Summary, Security, DonorSummary },
  data() {
    return {
      donorDetails: {},
      amount: null,
      custom_tip_amount: 0,
      isValid: false,
      recurringDonation: null,
      recurringDonationDay: null,
      hiPay: {
        tokenize: null,
        translations: null,
        create: null,
      },
      cardInstance: null,
      isLoading: false,
      summaryEditing: false,
    };
  },
  mounted() {
    this.donorDetails = JSON.parse(sessionStorage.getItem("donor_details"));
    this.amount = sessionStorage.getItem("donation_amount");
    this.recurringDonation = sessionStorage.getItem("donation_recurring");
    this.recurringDonationDay = sessionStorage.getItem("donation_dayofmonth");
    this.custom_tip_amount = parseFloat(sessionStorage.getItem("custom_tip_amount"));

    this.initPaymentMean();
  },
  methods: {
    summaryUpdated(data) {
      this.amount = data.amount;
      this.custom_tip_amount = data.custom_tip_amount;
      this.recurringDonationDay = data.day_of_month;
      this.summaryEditing = false;
    },
    initPaymentMean() {
      const paymentScript = document.createElement("script");
      paymentScript.type = `text/javascript`;
      paymentScript.src = this.$store.state.settings.cashinHipayTokenizationScriptUrl;
      paymentScript.onload = () => {
        this.initHiPay(this.$store.state.settings.tokenizationKey, this.$store.state.settings.tokenizationPassword);
      };
      paymentScript.onerror = () => {};
      document.body.appendChild(paymentScript);
    },
    initHiPay(key, password) {
      this.hiPay = window.HiPay({
        username: key,
        password: password,
        environment: key.indexOf("stage") !== -1 ? "stage" : "production",
        lang: this.$i18n.locale === "en" ? "en" : "fr",
      });

      let config = {
        template: "auto",
        selector: "hipay-hostedfields-form",
        multi_use: this.recurringDonation === "true",
        fields: {
          cardHolder: {
            defaultFirstname: this.donorDetails.firstName,
            defaultLastname: this.donorDetails.lastName,
          },
        },
      };

      this.cardInstance = this.hiPay.create("card", config);

      let that = this;
      this.cardInstance.on("change", function (event) {
        that.isValid = event.valid;
      });
    },
    async tokenize() {
      return new Promise((resolve, reject) => {
        if (this.isValid) {
          this.cardInstance.getPaymentData().then(
            function (response) {
              return resolve(response);
            },
            function (errors) {
              document.getElementById("hipay-error-message").innerHTML = errors[0].error;
              return reject(errors);
            }
          );
        }
      });
    },
    async pay() {
      this.isLoading = true;
      let tokenizationData = await this.tokenize();
      let donorDetails = JSON.parse(sessionStorage.getItem("donor_details"));
      let donation = {
        recurringDonationDay: this.recurringDonationDay,
        recurringDonation: this.recurringDonation === "true",
        amount: parseFloat(this.amount),
        tip: parseFloat(this.custom_tip_amount),
      };
      let result;
      try {
        result = await this.$axios.post(`/donationForms/forms/${this.$route.params.donationFormId}/donation`, {
          tokenizationData: tokenizationData,
          donorDetails: donorDetails,
          donation: donation,
        });
      } catch (err) {
        this.isLoading = false;
        return;
      }
      if (!result) return;
      sessionStorage.setItem("transaction_id", result.transactionId);
      sessionStorage.setItem("donation_id", result.donationId);
      sessionStorage.setItem("donation_status", result.status);
      if (result.status == paymentStatus.VALIDATED) {
        await this.$axios.post("/donationForms/sendEmail/success", {
          donationId: result.paymentReturnToken,
          donationFormId: this.$route.params.donationFormId,
          slug: this.$route.params.slug,
          status: result.status,
          to: donorDetails.email,
        });
      }
      // this.isLoading = false;
      if (result.forwardUrl) {
        window.location = result.forwardUrl;
      } else {
        this.$router.push(
          `/d/${this.$route.params.donationFormId}-${this.$route.params.slug}/${result.paymentReturnToken}/summary`
        );
      }
    },
  },
};
</script>

<style></style>
