<template>
  <v-container :class="$vuetify.breakpoint.smAndUp ? 'pt-0' : 'pa-0'">
    <v-row>
      <v-col cols="12" md="8">
        <v-row>
          <v-col>
            <v-card>
              <v-card-text>
                <v-list class="pa-0">
                  <v-list-item class="pa-0">
                    <v-list-item-icon class="mr-3">
                      <v-icon v-if="paymentStatus == paymentStatusEnum.VALIDATED" large color="success">
                        mdi-check-bold
                      </v-icon>
                      <v-icon v-else-if="paymentStatus == paymentStatusEnum.NOT_PAYED" large color="warning">
                        mdi-timer-sand
                      </v-icon>
                      <v-icon v-else-if="paymentStatus == paymentStatusEnum.PAYMENT_FAILURE" large color="error">
                        mdi-close-octagon-outline
                      </v-icon>
                      <v-icon v-else-if="paymentStatus == paymentStatusEnum.REFUNDED" large color="error">
                        mdi-cancel
                      </v-icon>
                      <v-icon v-else large color="error"> mdi-alert-circle </v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title v-if="paymentStatus == paymentStatusEnum.VALIDATED">
                        {{ $t("donationForm.successPage.acknowledgement") }}
                      </v-list-item-title>
                      <v-list-item-title v-else-if="paymentStatus == paymentStatusEnum.NOT_PAYED">
                        {{ $t("donationForm.successPage.pending") }}
                      </v-list-item-title>
                      <v-list-item-title v-else-if="paymentStatus == paymentStatusEnum.PAYMENT_FAILURE">
                        {{ $t("donationForm.successPage.refused") }}
                      </v-list-item-title>
                      <v-list-item-title v-else-if="paymentStatus == paymentStatusEnum.REFUNDED">
                        {{ $t("donationForm.successPage.refunded") }}
                      </v-list-item-title>
                      <v-list-item-title v-else>
                        {{ $t("donationForm.successPage.error") }}
                      </v-list-item-title>
                      <v-list-item-subtitle
                        v-if="donorDetails && paymentStatus == paymentStatusEnum.VALIDATED"
                        class="text-wrap"
                      >
                        {{ $t("donationForm.successPage.confirmation") }}
                        <strong>{{ donorDetails.email }}</strong>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card-text>
              <v-divider />
              <v-card-text>
                <v-row no-gutters>
                  <v-col class="text-right pr-5 text-no-wrap">{{
                    $t("donationForm.successPage.transactionNumber")
                  }}</v-col>
                  <v-col>{{ donation.transactionId }}</v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col class="text-right pr-5 text-no-wrap">{{
                    $t("donationForm.successPage.donationAmount")
                  }}</v-col>
                  <v-col>{{ donation.amount | amount("EUR") }}</v-col>
                </v-row>
                <v-row v-if="donation.tip" no-gutters>
                  <v-col class="text-right pr-5 text-no-wrap">{{ $t("donationForm.successPage.tip") }}</v-col>
                  <v-col>{{ donation.tip | amount("EUR") }}</v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col class="text-right pr-5 text-no-wrap">{{ $t("donationForm.successPage.total") }}</v-col>
                  <v-col>{{ (donation.amount + donation.tip) | amount("EUR") }}</v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col class="text-right pr-5 text-no-wrap">{{ $t("donationForm.successPage.recipient") }}</v-col>
                  <v-col
                    >{{ $t("donationForm.successPage.recipientIntro") }}
                    <strong>{{ donation.charityName }}</strong></v-col
                  >
                </v-row>
              </v-card-text>
              <template v-if="taxReceiptSettingsStatus.completed && paymentStatus == paymentStatusEnum.VALIDATED">
                <v-divider />
                <v-card-text v-if="!dataReady">
                  <v-row>
                    <v-col class="text-center">
                      <v-progress-circular indeterminate :size="50" color="primary"></v-progress-circular>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-text v-else>
                  <v-list v-if="!taxReceiptName">
                    <v-list-item>
                      <v-list-item-icon>
                        <v-icon large color="black"> mdi-timer-sand </v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title class="text-wrap">{{
                          $t("donationForm.successPage.taxReceiptNote")
                        }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                  <div v-else class="text-center my-3">
                    <v-btn color="secondary" raised @click="downloadTaxreceipt()">
                      {{ $t("donationForm.successPage.taxReceiptDownload") }}
                    </v-btn>
                  </div>
                  <div v-if="dataReady" class="text-caption">
                    {{ $t("donationForm.successPage.tips") }}
                    <a :href="taxReceiptUrl">{{ $t("donationForm.successPage.regroupTaxNote") }}</a>
                    {{ $t("donationForm.successPage.tipsEnd") }}
                  </div>
                </v-card-text>
              </template>
            </v-card>
          </v-col>
        </v-row>
        <v-row v-if="$store.state.donationForm.redirectLink && $vuetify.breakpoint.mobile">
          <v-col>
            <v-card>
              <v-card-text class="text-center text-wrap">
                {{ $t("donationForm.successPage.backlinkCartNote") }} <strong>{{ donation.charityName }}</strong>
              </v-card-text>
              <v-card-actions class="pb-4">
                <v-spacer />
                <v-btn raised type="submit" color="secondary" :href="$store.state.donationForm.redirectLink">
                  {{ $t("donationForm.successPage.backlinkButtonContinue") }}
                </v-btn>
                <v-spacer />
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-card>
              <v-card-text>
                <v-row>
                  <v-col cols="12" sm="4" class="text-center mt-0 mt-sm-7">
                    <v-img
                      v-if="$store.state.donationForm.logoUrl"
                      :src="$store.state.donationForm.logoUrl"
                      max-height="150"
                      max-width="250"
                    />
                  </v-col>
                  <v-col cols="12" sm="8">
                    <h5>{{ $store.state.donationForm.charity.charityProfile.frontDisplayName }}</h5>
                    <div class="text-justify text-wrap text-caption">
                      {{ $store.state.donationForm.charity.charityProfile.description }}
                    </div>
                    <v-checkbox
                      v-if="dataReady"
                      v-model="wantSubscription"
                      :label="$t('donationForm.successPage.charityNewsletterNote')"
                      @change="sendSubscription()"
                    ></v-checkbox>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="4">
        <v-row v-if="$store.state.donationForm.redirectLink && !$vuetify.breakpoint.mobile">
          <v-col>
            <v-card>
              <v-card-text class="text-center text-wrap">
                {{ $t("donationForm.successPage.backlinkCartNote") }} <strong>{{ donation.charityName }}</strong>
              </v-card-text>
              <v-card-actions class="pb-4">
                <v-spacer />
                <v-btn raised type="submit" color="secondary" :href="$store.state.donationForm.redirectLink">
                  {{ $t("donationForm.successPage.backlinkButtonContinue") }}
                </v-btn>
                <v-spacer />
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <Security />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <HelpChat />
  </v-container>
</template>

<script>
import Security from "./components/security.vue";
import HelpChat from "../../common/_helpChat.vue";
import paymentStatus from "../../util/paymentStatus";
const fileDownload = require("js-file-download");

export default {
  components: {
    Security,
    HelpChat,
  },

  data() {
    return {
      donorDetails: {},
      donation: {},
      dataReady: false,
      wantSubscription: false,
      paymentStatus,
      paymentStatusEnum: paymentStatus,
      taxReceiptName: false,
      taxReceiptUrl: "",
      taxReceiptSettingsStatus: {},
    };
  },
  async mounted() {
    this.donation.amount = parseFloat(sessionStorage.getItem("donation_amount"));
    this.donation.tip = parseFloat(sessionStorage.getItem("custom_tip_amount"));
    this.donation.transactionId = parseInt(sessionStorage.getItem("transaction_id"));
    this.donation.id = parseInt(sessionStorage.getItem("donation_id"));
    this.donation.charityName = this.$store.state.donationForm.charity.charityProfile.frontDisplayName;
    this.donorDetails = JSON.parse(sessionStorage.getItem("donor_details"));
    this.paymentStatus = sessionStorage.getItem("donation_status");
    if (!this.donation.transactionId) {
      await this.loadDonation();
    }
    if (this.paymentStatus == this.paymentStatusEnum.VALIDATED) {
      await this.getTaxReceiptEnabled();
      this.taxReceiptName = (
        await this.$axios.get(
          `/donationForms/forms/${this.$route.params.donationFormId}/donation/${this.$route.params.donationId}/taxreceipt`
        )
      ).name;

      await this.getTaxReceiptUrl();
    }
    this.dataReady = true;

    // Clear session storage
    sessionStorage.clear();
  },
  methods: {
    async downloadTaxreceipt() {
      let _this = this;
      await this.$axios
        .get(
          `/donationForms/forms/${this.$route.params.donationFormId}/donation/${this.$route.params.donationId}/downloadtaxreceipt`,
          {
            responseType: "blob",
          }
        )
        .then((response) => {
          fileDownload(response, _this.taxReceiptName);
        });
    },
    async loadDonation() {
      this.donation = await this.$axios.get(
        `/donationForms/forms/${this.$route.params.donationFormId}/donation/${this.$route.params.donationId}`
      );
      this.paymentStatus = this.donation.status;
      this.wantSubscription = this.donation.newsletterSubscription;
    },
    async sendSubscription() {
      await this.$axios.put(`/donationForms/donation/${this.donation.id}/setSubscription`, {
        subscription: this.wantSubscription,
      });
      if (this.wantSubscription) {
        this.$store.commit("SUCCESS", this.$t("donationForm.successPage.subscriptionStart"));
      } else {
        this.$store.commit("SUCCESS", this.$t("donationForm.successPage.subscriptionEnd"));
      }
    },
    async getTaxReceiptUrl() {
      this.taxReceiptUrl = await this.$axios.get(`/donationForms/donation/${this.donation.id}/taxReceiptLink`);
    },
    async getTaxReceiptEnabled() {
      this.taxReceiptSettingsStatus = await this.$axios.get(
        `/donationForms/forms/${this.$route.params.donationFormId}/donation/${this.$route.params.donationId}/taxreceipt/enabled`
      );
    },
  },
};
</script>

<style></style>
