import "./styles/styles.scss";
import "./plugins/filters";
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import i18n from "./plugins/i18n";
import validator from "./plugins/validator";
import store from "./store";
import axios from "./plugins/axios";
import "@mdi/font/scss/materialdesignicons.scss";
import WebFont from "webfontloader";
import VueMatomo from "vue-matomo";
import * as Sentry from "@sentry/vue";
import { CaptureConsole } from "@sentry/integrations";

WebFont.load({
  google: {
    families: ["Raleway:200,400,500,600,700,900"],
  },
});

Vue.config.productionTip = false;

let initVue = function (settings) {
  // Save settings to store
  store.commit("SETTINGS", settings);

  if (settings.analytics.matomo.host && settings.analytics.matomo.siteId) {
    Vue.use(VueMatomo, {
      host: settings.analytics.matomo.host,
      siteId: settings.analytics.matomo.siteId,
      router: router,
      trackerUrl: `${settings.analytics.matomo.host}/js/`,
      trackerScriptUrl: `${settings.analytics.matomo.host}/js/`,
    });
  }

  // Init vue
  new Vue({
    router,
    vuetify,
    i18n,
    validator,
    store,
    axios,
    render: (h) => h(App),
  }).$mount("#app");
};

Sentry.init({
  Vue,
  dsn: "https://46d27dc0998d47c1a359dc267c8d54a2@o1039846.ingest.sentry.io/6485178",
  ignoreErrors: ["PaymentAutofillConfig"],
  integrations: [new CaptureConsole({ levels: ["error"] })],
});

// Load settings from backend before vue init
axios.get("/public/settings").then((settings) => {
  initVue(settings);
});
