<template>
  <v-card>
    <v-card-title class="py-2 primary--text">
      <v-icon color="primary">mdi-charity</v-icon>&nbsp;
      {{ $store.state.donationForm.charity.charityProfile.frontDisplayName }}
    </v-card-title>
    <v-divider />
    <v-card-text class="pb-0">
      <v-row>
        <v-col lg="4" md="6" cols="12" class="text-caption text-justify">
          {{ $store.state.donationForm.charity.charityProfile.description }}
        </v-col>
        <v-col lg="4" md="6" cols="12" class="contact">
          <a
            v-if="$store.state.donationForm.charity.charityProfile.externalWebsite"
            target="_blank"
            :href="$store.state.donationForm.charity.charityProfile.externalWebsite"
            class="btn"
            >{{ $store.state.donationForm.charity.charityProfile.externalWebsite }}</a
          >
          <v-divider class="my-4" />
          <a
            v-if="$store.state.donationForm.charity.charityProfile.contactEmail"
            target="_blank"
            :href="'mailto://' + $store.state.donationForm.charity.charityProfile.contactEmail"
            class="btn"
            >{{ $store.state.donationForm.charity.charityProfile.contactEmail }}</a
          >
        </v-col>
        <v-col lg="4" cols="12">
          <img
            v-if="$store.state.donationForm.logoUrl"
            :src="$store.state.donationForm.logoUrl"
            class="mx-auto d-block contained-charity-logo"
          />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "CharityInfo",
  props: {
    value: Object,
  },
};
</script>

<style>
.contact > a {
  border-radius: 2.2rem;
  box-shadow: 1px 1px 3px rgb(0 0 0 / 30%);
  background: #ebeded;
  border: none;
  padding: 10px;
  display: block;
  text-align: center;
  color: black !important;
  font-weight: 600;
  text-decoration: none;
  text-shadow: 0px 0px 2px rgb(0 0 0 / 30%);
  font-size: 14px;
}
.contained-charity-logo {
  max-height: 200px;
  max-width: 100%;
}
</style>
