<template>
  <v-card color="primary">
    <v-card-text class="white--text pa-3">
      <img src="/assets/lock.svg" />
      <div
        class="my-2 text-caption"
        v-html="$t('display.secure', { name: $store.state.donationForm.charity.charityProfile.frontDisplayName })"
      ></div>
      <a href="https://www.alvarum.com">
        <img src="/assets/securedby.svg" alt="secured by Alvarum" title="secured by Alvarum" />
      </a>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "Security",
  props: {
    value: Object,
  },
};
</script>

<style></style>
