<template>
  <v-container fluid>
    <v-row class="white" justify="center" align="center">
      <v-col lg="10">
        <v-system-bar color="white" height="25">
          <img src="/assets/logo.svg" />
          <v-spacer></v-spacer>
          <lang-switcher />
        </v-system-bar>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import LangSwitcher from "../../../common/_langSwitcher.vue";

export default {
  components: { LangSwitcher },
  name: "Header",
  mounted: async function () {
    document.title = this.$store.state.donationForm.title
      ? this.$store.state.donationForm.title
      : this.$store.state.donationForm.name;
  },
};
</script>

<style></style>
