<template>
  <v-card>
    <v-form v-model="valid" @submit.prevent="save">
      <v-card-title class="primary--text py-2">
        <v-icon color="primary" class="mr-2">mdi-playlist-check</v-icon>
        {{ $t("donationForm.summary.title") }}
      </v-card-title>
      <v-card-text>
        <v-divider />
        <v-container fluid>
          <v-row no-gutters>
            <v-col class="text-right pt-1" cols="6">
              {{ $t("donationForm.summary.amount") }}
            </v-col>
            <v-col class="text-right pt-1" cols="5">
              <div v-if="amount_edit">
                <v-text-field
                  ref="input_amount"
                  :rules="[$v.required(), $v.min(1), $v.max(99999)]"
                  class="inplace_edit"
                  solo
                  v-model="amount"
                  suffix="€"
                  pattern="[0-9]{1-4}"
                  hide-details
                ></v-text-field>
              </div>
              <div v-else>
                {{ amount | amount("EUR") }}
              </div>
            </v-col>
            <v-col cols="1">
              <div v-if="amount_edit">
                <v-btn
                  icon
                  :disabled="!valid"
                  v-on:click="save"
                  color="secondary"
                  class="ml-2"
                  :title="$t('prop.modify')"
                  x-small
                >
                  <v-icon color="secondary">mdi-check-bold</v-icon>
                </v-btn>
              </div>
              <div v-else>
                <v-btn
                  icon
                  v-on:click="editAmount"
                  v-if="!in_edit_mode"
                  color="secondary"
                  class="ml-2"
                  :title="$t('prop.modify')"
                  x-small
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </div>
            </v-col>
          </v-row>
          <v-row no-gutters v-if="custom_tip_amount > 0 || custom_tip_edit">
            <v-col class="text-right pt-1" cols="6">
              {{ $t("donationForm.summary.custom_tip") }}
            </v-col>
            <v-col class="text-right pt-1" cols="5">
              <div v-if="custom_tip_edit">
                <v-text-field
                  ref="input_custom_tip"
                  :rules="[$v.required(), $v.min(0), $v.max(999)]"
                  class="inplace_edit"
                  solo
                  v-model="custom_tip_amount"
                  suffix="€"
                  hide-details
                ></v-text-field>
              </div>
              <div v-else>
                {{ custom_tip_amount | amount("EUR") }}
              </div>
            </v-col>
            <v-col cols="1">
              <div v-if="custom_tip_edit">
                <v-btn
                  icon
                  :disabled="!valid"
                  v-on:click="save"
                  color="secondary"
                  class="ml-2"
                  :title="$t('prop.modify')"
                  x-small
                >
                  <v-icon color="secondary">mdi-check-bold</v-icon>
                </v-btn>
              </div>
              <div v-else>
                <v-btn
                  icon
                  v-on:click="editCustomTip"
                  v-if="!in_edit_mode"
                  color="secondary"
                  class="ml-2"
                  :title="$t('prop.modify')"
                  x-small
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </div>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col class="text-right font-weight-medium" cols="6">
              {{ $t("donationForm.summary.total") }}
            </v-col>
            <v-col class="font-weight-medium text-right" cols="5">
              {{ total | amount("EUR") }}
            </v-col>
          </v-row>
        </v-container>
        <div class="my-2" v-if="recurring == true">
          <div v-if="day_edit">
            <v-row no-gutters>
              <v-col class="text-right pt-1" cols="6">
                {{ $t("donationForm.summary.dayEdit") }}
              </v-col>
              <v-col class="text-right pt-1" cols="5">
                <v-select
                  ref="input_day_of_month"
                  :items="recurringDays"
                  class="d-inline-block day-input mx-1"
                  v-model="day_of_month"
                  dense
                  :rules="[$v.required()]"
                  hide-details
                  style="max-width: 60px"
                >
                </v-select>
              </v-col>
              <v-col cols="1">
                <v-btn icon :disabled="!valid" v-on:click="save" color="secondary" :title="$t('prop.modify')" x-small>
                  <v-icon color="secondary">mdi-check-bold</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </div>
          <div v-else class="text-caption">
            {{
              $t("donationForm.summary.recurring", {
                day: day_of_month,
                date: start_date,
              })
            }}
            <v-btn
              icon
              v-on:click="editDay"
              v-if="!in_edit_mode"
              color="secondary"
              class="ml-2"
              :title="$t('prop.modify')"
              x-small
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </div>
        </div>
      </v-card-text>
    </v-form>
  </v-card>
</template>

<script>
const moment = require("moment");
export default {
  name: "Summary",
  data() {
    return {
      custom_tip_amount: 0,
      amount: 0,
      recurring: false,
      day_of_month: 0,
      total: 0,
      start_date: null,
      in_edit_mode: false,
      amount_edit: false,
      custom_tip_edit: false,
      day_edit: false,
      valid: false,
    };
  },
  mounted() {
    this.custom_tip_amount = parseFloat(sessionStorage.getItem("custom_tip_amount"));
    this.amount = parseFloat(sessionStorage.getItem("donation_amount"));
    this.recurring = sessionStorage.getItem("donation_recurring") == "true";
    this.day_of_month = parseInt(sessionStorage.getItem("donation_dayofmonth"));

    if (!this.amount) {
      this.$router.replace(`/d/${this.$route.params.donationFormId}-${this.$route.params.slug}`);
    }
    this.calculateDate();
  },
  methods: {
    calculateDate() {
      let mom = new moment();
      mom.add(1, "month");
      mom.set("date", this.day_of_month);
      this.start_date = mom.format("DD/MM/YYYY");

      this.total = this.amount + (this.custom_tip_amount || 0);
    },
    editAmount() {
      this.amount_edit = true;
      this.in_edit_mode = true;
      this.$emit("start-edit");
      setTimeout(() => {
        this.$refs.input_amount.focus();
      }, 100);
    },
    editCustomTip() {
      this.custom_tip_edit = true;
      this.in_edit_mode = true;
      this.$emit("start-edit");
      setTimeout(() => {
        this.$refs.input_custom_tip.focus();
      }, 100);
    },
    editDay() {
      this.day_edit = true;
      this.in_edit_mode = true;
      this.$emit("start-edit");
      setTimeout(() => {
        this.$refs.input_day_of_month.focus();
      }, 100);
    },
    save() {
      if (!this.valid) return false;

      this.amount_edit = false;
      this.custom_tip_edit = false;
      this.day_edit = false;
      this.in_edit_mode = false;

      sessionStorage.setItem("custom_tip_amount", this.custom_tip_amount);
      sessionStorage.setItem("donation_amount", this.amount);
      sessionStorage.setItem("donation_dayofmonth", this.day_of_month);

      this.calculateDate();

      this.total = parseFloat(this.amount) + (parseFloat(this.custom_tip_amount) || 0);

      this.$emit("finish-edit", {
        amount: this.amount,
        custom_tip_amount: this.custom_tip_amount,
        day_of_month: this.day_of_month,
      });
    },
  },
  computed: {
    recurringDays: () => [...Array(28)].map((_, i) => i + 1),
  },
};
</script>

<style>
.inplace_edit {
  max-width: 75%;
  margin-left: auto !important;
}

.inplace_edit .v-input__slot {
  padding-right: 6px !important;
}

.inplace_edit input {
  max-height: 0 !important;
  font-size: 14px;
  position: relative;
  top: -1px;
  text-align: right;
}

.inplace_edit.v-text-field.v-text-field--solo .v-input__control {
  min-height: 0;
}

.day-input {
  position: relative;
  top: -6px;
}
</style>
