<template>
  <div class="text-caption">
    <div>
      <v-icon small color="success">mdi-check-bold</v-icon>
      {{ $t("donationForm.singleForm.tax_deductible") }}
      <a v-if="!readMore" v-on:click="readMore = true" class="text-caption ml-2" href="javascript:void(0)">
        {{ $t("prop.read_more") }}
      </a>
      <a v-else v-on:click="readMore = false" class="text-caption ml-2" href="javascript:void(0)">
        {{ $t("prop.read_less") }}
      </a>
    </div>
    <div>
      <v-icon small>mdi-information</v-icon> {{ $t("donationForm.singleForm.cost_after") }}
      <b>
        {{ value | amount("EUR") }}
      </b>
    </div>
    <v-expand-transition>
      <div v-show="readMore" class="text-caption">
        <span v-if="$store.state.donationForm.charity.fiscalReceiptInformation.eligibleColuche">{{
          taxDescription_2
        }}</span>
        <span v-else>{{ taxDescription_1 }}</span>
        <br />
        <span v-if="$store.state.donationForm.charity.fiscalReceiptInformation.eligibleTEPA">{{
          taxDescription_tepa
        }}</span>
      </div>
    </v-expand-transition>
  </div>
</template>

<script>
export default {
  name: "TaxDeduction",
  props: {
    value: Number,
  },
  data() {
    return {
      readMore: false,
      taxDescription_1: this.$t("donationForm.singleForm.more_text_1", [
        this.$store.state.donationForm.charity.charityProfile.frontDisplayName,
      ]),
      taxDescription_2: this.$t("donationForm.singleForm.more_text_2", [
        this.$store.state.donationForm.charity.charityProfile.frontDisplayName,
      ]),
      taxDescription_tepa: this.$t("donationForm.singleForm.more_text_tepa", [
        this.$store.state.donationForm.charity.charityProfile.frontDisplayName,
      ]),
    };
  },
};
</script>

<style></style>
