import axios from "axios";
import store from "../store";
import Vue from "vue";
import router from "../router";

axios.defaults.baseURL = process.env.VUE_APP_API_BASE_PATH;

/**
 * Error handler
 */
axios.interceptors.response.use(
  (r) => r && r.data,
  (error) => {
    if (!error.response) {
      store.commit("I18N_MESSAGE", ["error", "errors.server.notreachable"]);
      return performRetry(error);
    }
    const status = error.response.status;
    const errorData = error.response.data.error || error.response.data;
    if (status === 401) {
      if (console && console.log) console.log("401 Unauthorized", errorData);
      store.dispatch("AUTHENTICATE", { auth: false }).then(() => {
        window.location.replace(
          `${store.state.settings.casLoginUrl}/login?service=${encodeURIComponent(
            store.state.settings.casServiceUrl
          )}?backUrl=${encodeURIComponent(window.location.pathname + window.location.search)}`
        );
      });
    } else if (status === 400) {
      if (console && console.log) console.log("Bad request", errorData);
      if (errorData.name === "AppError") {
        store.commit("I18N_MESSAGE", ["error", errorData.message, errorData.params]);
      } else {
        store.commit("I18N_MESSAGE", ["error", "errors.server.validation"]);
      }
    } else if (status === 500) {
      if (console && console.log) console.log("Technical error", errorData);
      store.commit("I18N_MESSAGE", ["error", "errors.server.technical"]);
    } else if (status === 404) {
      router.push("/notFound");
    } else {
      if (console && console.log) console.log("Unexpected error", status, errorData);
      store.commit("I18N_MESSAGE", ["error", "errors.server.unexpected"]);
    }
    return performRetry(error, errorData);
  }
);

axios.interceptors.request.use((r) => {
  if (r.url[0] != "/") {
    console.warn(`Missing '/' in url: ${r.url}`);
  }
  return r;
});

function performRetry(err, rejectData) {
  let rejectDataToReturn = rejectData || err;

  const status = err.response && err.response.status;
  const httpStatusesToFailFast = [
    400, 401, 402, 403, 405, 406, 407, 409, 410, 411, 412, 413, 414, 415, 416, 417, 418, 421, 422, 423, 424, 426, 428,
    429, 431, 451, 500, 501, 505, 506, 507, 508, 510, 511,
  ];

  if (status && httpStatusesToFailFast.some((value) => value === status)) return Promise.reject(rejectDataToReturn);

  let config = err.config || {};
  config.__retryCount = config.__retryCount || 5;
  if (config.__retryCount >= config.retry) {
    return Promise.reject(rejectDataToReturn);
  }

  config.__retryCount += 1;

  let backoff = new Promise(function (resolve) {
    setTimeout(function () {
      resolve();
    }, config.retryDelay || 5000);
  });

  return backoff.then(function () {
    return axios(config);
  });
}

Vue.prototype.$axios = axios;
export default axios;
