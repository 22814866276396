<template>
  <v-card>
    <v-card-title class="primary--text py-2">
      <v-icon color="primary" class="mr-2">mdi-account-details</v-icon>
      {{ $t("donationForm.donorSummary.title") }}
    </v-card-title>
    <v-card-text class="pb-0">
      <v-divider />
      <v-container fluid>
        <v-row no-gutters>
          <v-col class="pt-1" cols="4">
            {{ $t("donationForm.donorSummary.name") }}
          </v-col>
          <v-col class="pt-1" cols="8"> {{ donorDetails.firstName }} {{ donorDetails.lastName }} </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col class="pt-1" cols="4">
            {{ $t("donationForm.donorSummary.email") }}
          </v-col>
          <v-col class="pt-1" cols="8">
            {{ donorDetails.email }}
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col class="pt-1" cols="4">
            {{ $t("donationForm.donorSummary.address") }}
          </v-col>
          <v-col class="pt-1" cols="8">
            {{ donorDetails.address }}<br />
            {{ donorDetails.zipCode }} {{ donorDetails.city }}
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions class="pt-0">
      <v-spacer></v-spacer>
      <v-btn :to="`/d/${$route.params.donationFormId}-${$route.params.slug}/details`" color="secondary" text>
        <v-icon left>mdi-pencil</v-icon>
        {{ $t("prop.modify") }}
      </v-btn>
      <v-spacer></v-spacer>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "DonorSummary",
  data() {
    return {
      donorDetails: JSON.parse(sessionStorage.getItem("donor_details")) || {},
    };
  },
  mounted() {
    if (!this.donorDetails.firstName) {
      this.$router.replace(`/d/${this.$route.params.donationFormId}-${this.$route.params.slug}`);
    }
  },
};
</script>

<style></style>
