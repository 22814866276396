<template>
  <v-app
    ref="app"
    :class="{
      'primary-color-is-bright': isPrimaryColorBright,
      'secondary-color-is-bright': isSecondaryColorBright,
      'accent-color-is-bright': isAccentColorBright,
    }"
  >
    <v-snackbar
      v-for="(message, index) in $store.state.messages"
      :key="index"
      :color="message.type || 'info'"
      value="message.message"
      top
      :timeout="message.timeout || 7000"
      class="mt-5"
    >
      {{ message.key ? $t(message.key, message.params) : message.message }}
      <v-icon color="white" v-if="message.reactionAddons">{{ message.reactionAddons.type }}</v-icon>
    </v-snackbar>
    <router-view />
  </v-app>
</template>

<script>
import isColorBright from "./util/isColorBright";

export default {
  name: "App",
  computed: {
    isPrimaryColorBright() {
      return isColorBright(this.$vuetify.theme.themes["light"].primary);
    },
    isSecondaryColorBright() {
      return isColorBright(this.$vuetify.theme.themes["light"].secondary);
    },
    isAccentColorBright() {
      return isColorBright(this.$vuetify.theme.themes["light"].accent);
    },
  },
};
</script>
