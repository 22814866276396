<template>
  <v-form id="singleForm" v-model="valid">
    <v-card class="d-flex flex-column">
      <v-card-title class="primary--text text-h6">
        <v-icon color="primary">mdi-gift</v-icon>&nbsp;
        {{ $t("donationForm.singleForm.title") }}
      </v-card-title>
      <v-card-text>
        <v-btn-toggle
          v-model="selectedAmount"
          :rules="[$v.required()]"
          class="flex-wrap fix-borders"
          active-class="secondary white--text"
        >
          <v-btn v-for="index in value.donationGrid" :key="index" min-width="100px">
            <div v-if="index == 'custom'">
              <v-text-field
                :rules="value.donationGrid[selectedAmount] == 'custom' ? [$v.required(), $v.min(1), $v.max(99999)] : []"
                solo
                flat
                :label="$t('donationForm.other')"
                class="input-custom-amount"
                v-model="customAmount"
                suffix="€"
                type="number"
                min="1"
                hide-details
              ></v-text-field>
            </div>
            <div v-else class="text-h6 font-weight-medium px-1">
              {{ index | amountNoDecimal("EUR") }}
            </div>
          </v-btn>
        </v-btn-toggle>
      </v-card-text>
      <v-card-text class="pt-0">
        <TaxDeduction
          v-model="cost_after_deduction"
          v-if="$store.state.donationForm.charity.communicatesOnFiscalReduction"
        />
      </v-card-text>
      <hr class="primary" v-show="selectedAmount != null" />
      <v-expand-transition>
        <v-card-actions v-show="selectedAmount != null" class="flex-column flex-sm-row">
          <div v-if="$store.state.donationForm.charity.customTip" class="pa-2 pb-0">
            <div class="font-weight-bold text-caption">
              {{ $t("donationForm.singleForm.text_1") }}
            </div>
            <div class="mb-3 text-caption">
              {{ $t("donationForm.singleForm.custom_tip") }}
            </div>
            <v-select
              style="width: 170px"
              v-model="customTip"
              :items="custom_tip_options"
              item-text="desc"
              item-value="value"
              return-object
              outlined
              dense
              class="d-inline-block"
            >
            </v-select>
            <v-text-field
              :label="$t('donationForm.singleForm.contribution_amount')"
              v-if="customTip.value == 'other'"
              v-model="customTipOther"
              style="width: 150px"
              class="d-inline-block ml-5"
            />
          </div>
          <v-spacer />
          <v-btn color="secondary" v-on:click="nextStep()" :disabled="!valid" class="ma-2">
            {{ $t("prop.continue") }}
          </v-btn>
        </v-card-actions>
      </v-expand-transition>
    </v-card>
  </v-form>
</template>

<script>
import TaxDeduction from "./taxDeduction.vue";
export default {
  components: { TaxDeduction },
  name: "SingleForm",
  props: {
    value: Object,
  },
  data() {
    return {
      selectedAmount: null,
      customAmount: null,
      customTip: {},
      customTipOther: null,
      readMore: false,
      valid: false,
    };
  },
  mounted() {
    if (this.$store.state.donationForm.charity.customTip) {
      this.customTip = this.custom_tip_options[0];
    }
  },
  methods: {
    reset() {
      this.$data.selectedAmount = null;
    },
    nextStep() {
      sessionStorage.setItem("custom_tip_amount", this.custom_tip_amount);
      sessionStorage.setItem("donation_amount", this.donation_amount);
      sessionStorage.setItem("donation_recurring", false);
      this.bustIframeRedirect();
    },
    bustIframeRedirect() {
      let url = `/d/${this.$store.state.donationForm.id}-${this.$store.state.donationForm.slug}/details`;
      if (window.self !== window.top) {
        top.window.location.href = url;
      } else {
        this.$router.push(url);
      }
    },
  },
  watch: {
    selectedAmount(value) {
      if (value !== null) {
        this.$emit("selected");
      }
    },
  },
  computed: {
    donation_amount: function () {
      let amount = 0;
      if (this.value.donationGrid[this.selectedAmount] == "custom") {
        amount = this.customAmount;
      } else {
        amount = this.value.donationGrid[this.selectedAmount];
      }
      return amount;
    },
    custom_tip_amount: function () {
      if (!this.customTip || !this.customTip.value) return 0;
      let amount = 0;
      if (this.customTip.value == "other") {
        amount = this.customTipOther;
      } else {
        amount = (this.donation_amount * this.customTip.value) / 100;
      }
      return amount;
    },
    custom_tip_options: function () {
      return [
        { value: "15", desc: "15% (" + this.$options.filters.amount(this.donation_amount * 0.15, "EUR") + ")" },
        { value: "10", desc: "10% (" + this.$options.filters.amount(this.donation_amount * 0.1, "EUR") + ")" },
        { value: "5", desc: "5% (" + this.$options.filters.amount(this.donation_amount * 0.05, "EUR") + ")" },
        { value: "0", desc: this.$t("donationForm.singleForm.tip_none") },
        { value: "other", desc: this.$t("donationForm.singleForm.tip_other") },
      ];
    },
    cost_after_deduction: function () {
      let result = 0;
      if (this.$store.state.donationForm.charity.fiscalReceiptInformation.eligibleColuche) {
        const colucheMaxAmount = this.$store.state.settings.fiscalReduction.colucheDonationLimit;
        if (this.donation_amount && colucheMaxAmount < parseFloat(this.donation_amount)) {
          let colucheAmount = colucheMaxAmount * this.$store.state.settings.fiscalReduction.coluche;
          let stdAmount =
            (this.donation_amount - colucheMaxAmount) * this.$store.state.settings.fiscalReduction.standard;
          result = colucheAmount + stdAmount;
        } else {
          result = this.donation_amount * this.$store.state.settings.fiscalReduction.coluche;
        }
      } else {
        result = this.donation_amount * this.$store.state.settings.fiscalReduction.standard;
      }
      return result + (this.custom_tip_amount ? parseFloat(this.custom_tip_amount) : 0);
    },
  },
};
</script>

<style scoped>
.input-custom-amount {
  width: 110px;
  position: relative;
  top: 0.5em;
}
.input-custom-amount > div {
  background: transparent;
}
.input-custom-amount >>> div {
  min-height: auto;
  background: transparent;
}
.input-custom-amount >>> label {
  font-size: 0.8em;
  font-weight: bold;
}
.input-custom-amount >>> input {
  -moz-appearance: textfield;
}
.input-custom-amount >>> input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
hr {
  border-top: 2px solid #21a6df;
}
.v-application--is-ltr .fix-borders > .v-btn.v-btn:not(:first-child) {
  border-left-width: 1px !important;
}
</style>
