import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    messages: [],
    details: {},
    recurring: false,
    dayOfMonth: 28,
    amount: null,
    loading: false,
    charity: null,
    settings: null,
  },
  mutations: {
    LOADING(state, loading) {
      state.loading = !!loading;
    },
    SUCCESS(state, message) {
      state.messages.push({
        type: "success",
        message: message,
        timeout: 4000,
      });
    },
    ERROR(state, message) {
      state.messages.push({
        type: "error",
        message: message,
      });
    },
    WARNING(state, message) {
      state.messages.push({
        type: "warning",
        message: message,
      });
    },
    INFO(state, message) {
      state.messages.push({
        type: "info",
        message: message,
        timeout: 6000,
      });
    },
    MESSAGE(state, [type, message, timeout]) {
      state.messages.push({
        type: type,
        message: message,
        timeout: timeout,
      });
    },
    I18N_MESSAGE(state, [type, key, params, timeout]) {
      state.messages.push({
        type: type,
        key: key,
        params: params,
        timeout: timeout,
      });
    },
    CHARITY(state, charity) {
      state.charity = charity;
    },
    SETTINGS(state, settings) {
      state.settings = settings;
    },
  },
});
